import { useEffect, useMemo, useState } from "react";

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Tooltip } from "@mui/material";

import { useApiContextOrMockAPIContext } from "../../../api/context";
import LoadingButton from "../../../Components/LoadingButton";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { isCustomerInPresentationMode } from "../../../Context/useCustomerOrPresentationModeCustomer";
import { useUserContext } from "../../../Context/UserContext";
import { consoleErrorWithSentry } from "../../../utils";
import { useFullScreen } from "../../../utils/dialog";
import { type Insight } from "../../Insights/types";
import { useJiraFields, useJiraIssueTypes, useJiraProjects } from "../api";
import { type JiraIssueType, type JiraProject } from "../types";
import { FieldsSelects } from "./FieldsSelects";
import { IssueTypeSelect } from "./IssueTypeSelect";
import { ProjectsSelect } from "./ProjectsSelect";

type Props = {
  insight: Insight;
  jiraURL: string | undefined;
  onClose: () => void;
};

export const CreateThreadModal = ({ insight, jiraURL, onClose }: Props) => {
  const { isMobile: isSmallScreen } = useFullScreen("sm");

  const defaultSummary = insight.title;
  const defaultDescription = `## This issue was created from a [DoiT Insight](https://console.doit.com/customers/${insight.customerId}/insights/${insight.providerId}/${insight.key}).\n\n${insight.shortDescription}\n\n---\n\n${insight.detailedDescriptionMdx}`;

  const defaultFieldValues = useMemo(
    () => ({
      summary: defaultSummary,
      description: defaultDescription,
    }),
    [defaultDescription, defaultSummary]
  );

  const [selectedProject, setSelectedProject] = useState<null | JiraProject>(null);
  const [selectedIssueType, setSelectedIssueType] = useState<null | JiraIssueType>(null);

  const [fieldValues, setFieldValues] = useState<Record<string, string>>(defaultFieldValues);

  const [creating, setCreating] = useState(false);
  const [isCreateError, setIsCreateError] = useState(false);

  const { customer: originalCustomer, customerOrPresentationModeCustomer: customer } = useCustomerContext();
  const isPresentationMode = isCustomerInPresentationMode(originalCustomer);
  const api = useApiContextOrMockAPIContext(isPresentationMode);

  const { userModel } = useUserContext();
  const { isDoitEmployee } = useAuthContext();

  const { data: projects, isLoading: isLoadingProjects, isError: isErrorProjects } = useJiraProjects();

  const {
    data: issueTypes,
    isLoading: isLoadingIssueTypes,
    isError: isErrorIssueTypes,
  } = useJiraIssueTypes(selectedProject);

  const {
    data: fields,
    isLoading: isLoadingFields,
    isError: isErrorFields,
  } = useJiraFields(selectedProject, selectedIssueType);

  // When issue types changed, pre-select one of the available ones
  useEffect(() => {
    if (!issueTypes || isErrorIssueTypes) {
      setSelectedIssueType(null);
      return;
    }

    // Set the 'default' issue type to 'Task' (if it exists) so we can get started on the next API call ASAP
    const taskIssueType = issueTypes.find((issueType) => issueType.name === "Task");
    if (taskIssueType) {
      setSelectedIssueType(taskIssueType);
    } else {
      setSelectedIssueType(issueTypes[0]);
    }
  }, [issueTypes, isErrorIssueTypes]);

  // When fields changed, select initial field values
  useEffect(() => {
    if (!fields) {
      return;
    }

    const initialFieldValues = { ...defaultFieldValues };
    fields.forEach((field) => {
      // Ignore these, they are already set above
      if (field.key === "summary" || field.key === "description") {
        return;
      }

      // For all other fields, set an empty value
      initialFieldValues[field.key] = "";
    });

    // In case we had any previous values, keep them!
    setFieldValues((prev) => ({
      ...initialFieldValues,
      ...prev,
    }));
  }, [defaultFieldValues, fields]);

  const handleCreateThreadSubmit = async () => {
    setCreating(true);
    setIsCreateError(false);

    // We should always have the User ID by this point, something isn't right if we don't
    if (!userModel) {
      consoleErrorWithSentry("cannot get current user");

      setCreating(false);
      setIsCreateError(true);

      return;
    }

    const data = {
      reporterId: userModel.id,
      source: "insight",
      sourceData: {
        insightKey: insight.key,
        providerId: insight.providerId,
      },
      fields: {
        project: selectedProject!.id,
        issuetype: selectedIssueType!.id,
      },
      jiraUrl: jiraURL,
    };

    // Set the payload structure depending on the schema type - add more here as we run into issues
    fields!.forEach((field) => {
      switch (field.schema.type) {
        case "option":
          data.fields[field.key] = { id: fieldValues[field.key] };
          break;
        case "number":
          data.fields[field.key] = parseInt(fieldValues[field.key]);
          break;
        default:
          data.fields[field.key] = fieldValues[field.key];
      }
    });

    try {
      const url = `threads/jira/thread?customerID=${customer.id}`;
      await api.post(url, data);

      setCreating(false);

      onClose();
    } catch (e) {
      consoleErrorWithSentry(e);
      setCreating(false);
      setIsCreateError(true);
    }
  };

  const isFormValid = Object.values(fieldValues).every((value) => !!value);

  return (
    <Dialog open={true} fullScreen={isSmallScreen} fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitle>Create thread</DialogTitle>
      <DialogContent>
        {isDoitEmployee && !isPresentationMode ? (
          <Alert severity="warning" sx={{ mb: 2 }}>
            <strong>You're logged in as a DoiT employee</strong> - you can click through this form, but you will not be
            able to create a thread in the customer's Jira instance. To create a thread in DoiT's Jira, log in as or
            impersonate a regular user in the <kbd>doit.com</kbd> tenant.
          </Alert>
        ) : null}

        <ProjectsSelect
          isLoading={isLoadingProjects}
          isError={isErrorProjects}
          projects={projects}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
          setSelectedIssueType={setSelectedIssueType}
        />

        {selectedProject !== null && !isErrorProjects ? (
          <IssueTypeSelect
            isLoading={isLoadingIssueTypes}
            isError={isErrorIssueTypes}
            issueTypes={issueTypes}
            selectedProject={selectedProject}
            selectedIssueType={selectedIssueType}
            setSelectedIssueType={setSelectedIssueType}
          />
        ) : null}

        {selectedProject !== null && !isErrorProjects && selectedIssueType !== null && !isErrorIssueTypes ? (
          <FieldsSelects
            isLoading={isLoadingFields}
            isError={isErrorFields}
            fieldValues={fieldValues}
            setFieldValues={setFieldValues}
            fields={fields}
          />
        ) : null}

        {isCreateError ? (
          <Alert severity="error" sx={{ mb: 2 }}>
            An unknown error occurred during thread creation. Please try again later.
          </Alert>
        ) : null}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="text" onClick={onClose} sx={{ mr: 1 }}>
          Cancel
        </Button>

        <Tooltip title={isPresentationMode ? "Threads cannot be created in Presentation mode." : ""} placement="bottom">
          <span>
            <LoadingButton
              loading={creating}
              variant="contained"
              disabled={
                !fields ||
                fields.length === 0 ||
                !isFormValid ||
                isErrorProjects ||
                isErrorIssueTypes ||
                isErrorFields ||
                isDoitEmployee ||
                isPresentationMode
              }
              onClick={handleCreateThreadSubmit}
              mixpanelEventId="threads.create"
            >
              Create thread
            </LoadingButton>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};
